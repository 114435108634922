import * as React from "react"
import Header from "../components/headerSection";
import NavBar from "../components/navBar";

const SuccessPage = () => {
  return (
    <main>
      <div className="fixed z-50 w-full shadow">
        <Header />
        <NavBar />
      </div>
      <div className="h-screen bg-red-900">
          <h1>Thank you for submitting!</h1>
      </div>
    </main>
  )
}

export default SuccessPage
